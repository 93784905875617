var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"login pa-0 d-flex flex-column page"},[_c('v-row',{staticClass:"form-login primary-gradient-default ml-0 mb-0 rounded-tl-xl",attrs:{"justify":"center"}},[_c('v-container',{staticClass:"align-self-center"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10","xs":"8","sm":"8","md":"6","lg":"4"}},[_c('img',{staticClass:"white-logo",attrs:{"alt":"Logotipo Branco","src":require("@/assets/logo-black.svg"),"width":"250px"}})]),_c('v-col',{attrs:{"cols":"10","xs":"8","sm":"8","md":"6","lg":"4"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-form',[_c('div',{staticClass:"cpf-input",on:{"click":function($event){_vm.hasAccount && !_vm.$wait.waiting('loading')
                    ? _vm.setAuthKind(null)
                    : null}}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],staticClass:"input",attrs:{"disabled":_vm.$wait.waiting('loadingAuthKind') || _vm.hasAccount,"autocomplete":"off","id":"cpf","label":"CPF","name":"cpf"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login()}},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}})],1),_c('div',{staticClass:"password-input",class:!_vm.hasAccount ? 'hide' : ''},[_c('v-text-field',{staticClass:"input",attrs:{"disabled":_vm.$wait.waiting('loading'),"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"name":"input-10-1","label":"SENHA"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]),_c('router-link',{staticClass:"forgot-password primary--text mb-4 text-decoration-none float-left text-link",class:{
                hide: !_vm.hasAccount,
                disabled: _vm.$wait.waiting('loading'),
              },attrs:{"to":!_vm.$wait.waiting('loading')
                  ? { name: 'forgot-password', params: { cpf: _vm.cpf } }
                  : {}}},[_vm._v(" Esqueci minha senha ")])],1),_c('v-btn',{staticClass:"btn mb-4",attrs:{"loading":_vm.$wait.waiting('loading') || _vm.$wait.waiting('loadingAuthKind'),"block":"","large":"","color":"secondary-gradient primary--text"},on:{"click":function($event){return _vm.login()}}},[_vm._v(" ENTRAR "),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1),_c('v-dialog',{staticClass:"pa-4",attrs:{"show-cancel-button":false,"show-confirm-button":false},model:{value:(_vm.showDialogAuthKind),callback:function ($$v) {_vm.showDialogAuthKind=$$v},expression:"showDialogAuthKind"}},[_c('h4',[_vm._v("Escolha o seu nível de acesso")]),_vm._l((_vm.authKindsPossibles),function(kind,index){return _c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,staticClass:"auth-kind-card elevation-3 mt-4 px-3",on:{"click":function($event){return _vm.selectAuthKind(kind.value)}}},[_c('div',{staticClass:"auth-kind-avatar",class:kind.color},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(kind.icon))])],1),_c('div',{staticClass:"ml-2"},[_c('span',[_vm._v(_vm._s(kind.name))])])])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }