<template>
  <v-row justify="center" class="scroll-y">
    <v-col cols="12" sm="10" md="8" lg="6">
      <v-card ref="form">
        <h2 class="primary--text pl-3 pt-3">
          {{ isEdit ? "Alterar comunicado" : "Cadastrar comunicado" }}
        </h2>
        <v-card-text>
          <div v-if="data.createdAt">
            <strong>Data de criação:</strong>
            {{ data.createdAt | FormatDate("datetime") }}
          </div>

          <v-text-field
            v-model="data.title"
            label="Título"
            placeholder="Ex: Descontos nas oficinas de Campinas/SP"
            required
          ></v-text-field>

          <v-textarea
            v-model="data.description"
            label="Descrição"
            placeholder="Ex: comunicado válida do dia 22/10 ao 15/11"
            required
          ></v-textarea>

          <v-text-field
            v-model="data.link"
            label="Link"
            placeholder="Ex: https://pontual.org.br/promocao-oficinas"
          ></v-text-field>

          <v-text-field
            v-model="data.phone"
            label="Telefone"
            placeholder="Ex: (11) 99999-9999"
            v-mask="['(##) #####-####']"
          ></v-text-field>

          <v-text-field
            v-model="data.address"
            label="Endereço"
            placeholder="Ex: Rua da Inestencia 000, Bairro - Cidade/UF"
          ></v-text-field>

          <template v-if="data.target">
            <div class="mt-3"><strong>Visibilidade</strong></div>
            <v-checkbox
              @change="cleanTarget()"
              v-model="data.target.all"
              label="Visível para todos no app"
            ></v-checkbox>

            <div class="mb-6" v-if="!data.target.all">
              <div class="d-flex">
                <v-checkbox
                  v-for="role in roles"
                  :key="role.value"
                  v-model="data.target.roles"
                  :label="role.text"
                  :value="role.value"
                  class="mr-3"
                  multiple
                ></v-checkbox>
              </div>

              <template v-if="associatedSelected">
                <v-autocomplete
                  v-model="data.target.state"
                  @change="data.target.city = null"
                  item-text="text"
                  item-value="value"
                  :items="states"
                  label="Visível para associados do UF"
                  chips
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  v-model="data.target.city"
                  :disabled="!data.target.state"
                  :items="cities"
                  item-text="name"
                  item-value="value"
                  label="Visível para associado da cidade"
                  multiple
                  chips
                ></v-autocomplete>
              </template>
            </div>
          </template>

          <div class="upload-field">
            <div class="mb-3">
              <strong>Imagem do banner* - PNG ou JPG (930px x 600px)</strong>
            </div>

            <v-card v-if="data.banner" class="mx-auto">
              <v-img
                class="white--text align-end"
                height="200px"
                :src="data.banner"
              >
                <v-btn
                  class="ma-3"
                  color="red"
                  dark
                  @click="data.banner = null"
                >
                  <v-icon color="white" class="mr-2">mdi-close</v-icon> Remover
                </v-btn>
              </v-img>
            </v-card>

            <template v-else>
              <input
                ref="inputFile"
                class="d-none"
                type="file"
                @change="didSelectFile($event)"
              />
              <v-btn
                color="blue"
                dark
                size="small"
                type="primary"
                class="bold"
                @click="$refs.inputFile.click($event)"
                :loading="$wait.waiting('uploading')"
              >
                <v-icon color="white" size="18" class="mr-2">uil-upload</v-icon
                >Upload
              </v-btn>
            </template>
          </div>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn @click="$router.back()" text>
            <v-icon>mdi-chevron-left</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="red"
            dark
            class="mt-4"
            @click="confirmRemove()"
            :loading="$wait.waiting('removing')"
            v-if="data.id"
          >
            <v-icon>mdi-delete</v-icon>
            Excluir
          </v-btn>
          <v-btn
            color="green"
            :disabled="!allRequiredFieldsFilled"
            large
            class="btn mt-4 secondary primary--text"
            @click="submit()"
            :loading="
              $wait.waiting('creating') ||
              $wait.waiting('updating') ||
              $wait.waiting('removing')
            "
          >
            {{ isEdit ? "Salvar" : "Cadastrar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { mapWaitingActions } from "vue-wait";
import _ from "lodash";
import estadosCidades from "@/helpers/estadosCidades.json";

export default {
  name: "PromotionalEditor",

  data() {
    return {
      showCompanyPicker: false,
      isEdit: false,
      roles: [
        { text: "Associado", value: "associated" },
        { text: "Consultor", value: "employee" },
        { text: "Consultor líder", value: "owner" },
        { text: "Admin", value: "admin" },
        { text: "Backoffice", value: "backoffice" },
      ],
      data: {
        createdAt: null,
        title: null,
        description: null,
        phone: null,
        link: null,
        banner: null,
        target: {
          companiesIds: ["*"],
          all: true,
          city: null,
          state: null,
          roles: null,
        },
      },
    };
  },

  created() {
    const id = _.get(this.$route, "params.id", null);
    const data = _.get(this.$route, "params.data", null);

    if (id && id != "new" && data) {
      this.data = Object.assign({}, data);
      this.isEdit = true;
    } else if (id && id != "new" && !data) {
      return this.$router.replace({ name: "promotional" });
    }
  },

  computed: {
    states() {
      return estadosCidades.states;
    },

    cities() {
      const stateValue = _.get(this.data, "target.state", null);
      if (!stateValue) {
        return [];
      }
      const state = this.states.find((s) => s.value == stateValue);
      return estadosCidades.cities
        .filter((city) => city.stateId == state.id)
        .map((city) => ({
          ...city,
          value: city.name.toUpperCase(),
        }));
    },

    allRequiredFieldsFilled() {
      const roles = _.get(this.data, "target.roles.length", 0);
      const all = _.get(this.data, "target.all", false);

      if (!all && !roles) {
        return false;
      }

      const fields = ["title", "banner"];
      return fields.every((field) => this.data[field]);
    },

    associatedSelected() {
      return _.includes(_.get(this.data, "target.roles", []), "associated");
    },
  },

  methods: {
    ...mapActions("app", ["upload"]),
    ...mapActions("promotional", ["create", "update", "remove"]),
    ...mapWaitingActions("app", {
      upload: "uploading",
    }),
    ...mapWaitingActions("promotional", {
      create: "creating",
      update: "updating",
      remove: "removing",
    }),

    didSelectFile($event) {
      let file = $event.target.files && $event.target.files[0];
      if (!file) return;
      this.startUpload(file);
    },

    async startUpload(file) {
      this.$refs.inputFile.value = null;
      if (file.size) {
        const fileSize = file.size;
        const maxSize = 1024 * 1024 * 15; // 15MB
        if (fileSize > maxSize) {
          return this.$notify({
            message:
              "O tamanho máximo de um arquivo para fazer upload é de 15mb",
            duration: 8000,
          });
        }
      }
      const attachment = await this.upload(file);
      if (attachment && attachment.url) {
        this.data.banner = attachment && attachment.url;
      }
    },

    submit() {
      if (this.isEdit) {
        return this.updateRequest();
      }

      return this.createRequest();
    },

    async createRequest() {
      const data = Object.assign({}, this.data);
      data.target.companiesIds = ["*"];
      const result = await this.create(data);
      if (!result) return;

      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Cadastrado com sucesso",
      });
      this.$router.replace({ name: "promotional" });
    },

    async updateRequest() {
      const data = Object.assign({}, this.data);
      data.target.companiesIds = ["*"];
      const result = await this.update({
        id: this.data.id,
        promotional: data,
      });
      if (!result) return;

      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Dados atualizados com sucesso",
      });
    },

    cleanTarget() {
      this.data.target.state = null;
      this.data.target.city = null;
      this.data.target.roles = null;
    },

    async confirmRemove() {
      if (
        !window.confirm(
          "Deseja realmente excluir? Não será possível recuperar após a exclusão"
        )
      )
        return;

      const result = await this.remove(this.data.id);
      if (!result) return;

      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Excluído com sucesso",
      });
      this.$router.replace({ name: "promotional" });
    },
  },
};
</script>

<style scoped>
.scroll-y {
  overflow-y: auto;
  height: calc(100vh - 56px);
}
</style>
