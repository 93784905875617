<template>
  <div>
    <v-navigation-drawer
      color="primary"
      v-model="drawer"
      :mini-variant.sync="mini"
      class="main-menu"
      app
      dark
      width="240"
      style="display: flex; flex-direction: column"
    >
      <center class="mt-3 mb-4" v-if="$vuetify.breakpoint.lgOnly && !mini">
        <img class="logotipo" src="@/assets/logo-white.svg" height="100" />
      </center>
      <div class="logo-minified mt-3 mb-4" v-if="mini">
        <img class="logotipo" src="@/assets/logo-white.svg" height="36" />
      </div>
      <v-divider></v-divider>
      <v-list style="flex: 1 1 auto" dense>
        <template v-for="(items, group, i) in menuGroups">
          <!-- Group title -->
          <div v-if="group" :key="group" row align-center>
            <v-flex>
              <v-subheader v-if="group">
                {{ group }}
              </v-subheader>
            </v-flex>
          </div>

          <!-- List of group -->
          <v-list-item
            v-for="item in items"
            :key="item.title + item.route"
            @click="open(item)"
            :class="{ 'v-list-item--active': isCurrentRoute(item) }"
          >
            <v-tooltip :disabled="!mini" right>
              <template v-slot:activator="{ on: tooltip }">
                <v-list-item-icon v-on="{ ...tooltip }">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
              </template>
              {{ item.title }}
            </v-tooltip>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider
            :key="i"
            v-if="i < Object.keys(menuGroups).length - 1"
            class="my-3"
            dark
          ></v-divider>
        </template>
      </v-list>

      <div
        :class="!mini ? 'mr-2' : ''"
        :style="{ textAlign: mini ? 'center' : 'right' }"
        style="height: 50px"
      >
        <v-btn
          icon
          @click.stop="mini = !mini"
          v-if="!$vuetify.breakpoint.xsOnly"
        >
          <v-icon>{{ mini ? "mdi-chevron-right" : "mdi-chevron-left" }}</v-icon>
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="white"
      class="toolbar"
      dark
      flat
      :absolute="!$vuetify.breakpoint.xsOnly"
    >
      <v-app-bar-nav-icon
        class="primary--text hidden-lg-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-layout row flex class="title grey--text hidden-md-and-down"></v-layout>
      <v-layout row align-center justify-center class="flex">
        <img src="@/assets/logo.svg" height="32" class="hidden-lg-and-up" />
      </v-layout>

      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            row
            wrap
            align-center
            style="height: 56px; flex: none"
            v-bind="attrs"
            v-on="on"
            class="hidden-md-and-down mr-3"
          >
            <v-layout justify-center column class="mr-3">
              <div class="black--text text-left">{{ user && user.name }}</div>
              <div class="caption grey--text">CPF: {{ user && user.cpf }}</div>
            </v-layout>
            <v-avatar :size="38" color="primary">
              <span class="white--text">{{ user && user.name[0] }}</span>
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout()">
            <v-list-item-title>Sair da conta</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "MainMenu",

  data() {
    return {
      menus: [
        {
          title: "Dashboard",
          icon: "mdi-chart-box",
          group: "Interno",
          route: "/",
          roles: ["owner", "admin"],
        },
        {
          title: "Comunicados",
          icon: "mdi-bullhorn",
          group: "Interno",
          route: "/promotional",
          roles: "admin",
        },
        {
          title: "Eventos comunicados",
          icon: "mdi-bell-ring",
          group: "Interno",
          route: "/occurrence",
          roles: "admin",
        },
        {
          title: "Cotações",
          icon: "mdi-email-check",
          group: "Interno",
          route: "/quotation",
          roles: ["owner", "admin"],
        },
        {
          title: "Vistorias",
          icon: "mdi-text-box-search-outline",
          group: "Interno",
          route: "/inspection",
          roles: ["backoffice", "admin"],
        },
        {
          title: "Contratos",
          icon: "mdi-file-document-edit",
          group: "Interno",
          route: "/contract",
          roles: "admin",
        },
        {
          title: "Regionais",
          icon: "mdi-office-building-marker",
          group: "Administrativo",
          route: "/company",
          roles: "admin",
        },
        {
          title: "Usuários",
          icon: "mdi-account-circle",
          group: "Administrativo",
          route: "/user",
          roles: "admin",
        },
        /*{
          title: "Configurações",
          icon: "mdi-settings",
          route: "/setting",
        },*/
      ],
      mini: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("app", ["showMainMenu", "features"]),
    menuGroups() {
      return _.groupBy(this.itemsMenu, (item) => (item && item.group) || "");
    },

    itemsMenu() {
      if (!this.user || !this.user.roles) return [];
      return [].concat(this.menus).filter(this.isAllowed);
    },

    drawer: {
      get() {
        if (this.$vuetify.breakpoint.lgAndUp) return true;

        return this.showMainMenu;
      },
      set(val) {
        this.setShowMainMenu(val);
      },
    },

    currentRoute() {
      return _.get(this.$router, "history.current", "");
    },
  },

  watch: {
    mini(newVal) {
      localStorage.setItem("navigationMinified", newVal);
    },
  },

  created() {
    let navigationMinified = localStorage.getItem("navigationMinified");
    if (navigationMinified) {
      this.mini = JSON.parse(navigationMinified);
    }
    this.getMe();
    this.fetchContext();
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchContext();
    });
  },

  methods: {
    ...mapActions("auth", ["fetchContext", "getMe"]),
    ...mapMutations("app", ["setShowMainMenu"]),
    ...mapActions("auth", ["logout"]),

    isCurrentRoute(item) {
      if (item.route === "/") {
        return this.$route.name == "home";
      }
      return this.$route.path.startsWith(item.route);
    },

    isAllowed(menu) {
      if (!menu || !menu.roles) {
        return true;
      }

      return _.castArray(menu.roles).some((role) =>
        this.user.roles.includes(role)
      );
    },

    async open(itemMenu) {
      if (!itemMenu || !itemMenu.route) return;
      try {
        await this.$router.push({
          path: itemMenu.route,
          query: itemMenu.query || {},
        });
      } catch (err) {
        return err;
      }
    },
  },
};
</script>
<style>
.v-toolbar.toolbar {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03) !important;
}
.v-toolbar--fixed.toolbar {
  z-index: 4;
}
.logo-minified {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-menu.v-navigation-drawer--mini-variant .v-list__tile {
  padding: 0 20px;
}
.logotipo {
  max-width: 80%;
}
</style>
