<template>
  <v-row justify="center" class="scroll-y" v-if="data">
    <v-col cols="12" sm="10" md="10" lg="10">
      <v-card ref="form" class="pa-3">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="5">
            <h2 class="primary--text pl-3 pt-3">
              {{ isEdit ? "Detalhes da vistoria" : "Criar vistoria" }}
            </h2>
          </v-col>

          <v-col cols="12" sm="12" md="7" align="right">
            <v-btn
              :disabled="status == 'waiting_correction'"
              color="yellow"
              large
              class="btn mr-2 black--text"
              @click="declineRequest()"
              :loading="$wait.waiting('declining')"
              v-if="
                status == 'waiting_approval' || status == 'waiting_correction'
              "
            >
              <v-icon class="mr-2">mdi-backup-restore</v-icon>
              Enviar para correção
            </v-btn>

            <v-btn
              :disabled="status == 'waiting_correction'"
              color="green"
              large
              class="btn mr-2 white--text"
              @click="approveRequest()"
              :loading="$wait.waiting('approving')"
              v-if="
                status == 'waiting_approval' || status == 'waiting_correction'
              "
            >
              <v-icon class="mr-2">mdi-check</v-icon>
              Aprovar vistoria
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text>
          <div v-if="data.createdAt">
            <strong>Vistoria criada em:</strong>
            {{ data.createdAt | FormatDate("datetime") }}
          </div>

          <div v-if="data.author">
            <div><strong>Vistoria criada por:</strong></div>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="data.author.name"
                  placeholder="Ex: John Doe"
                  solo
                  readonly
                  required
                >
                  <template v-slot:append>
                    <v-btn
                      class="ma-0"
                      text
                      @click="
                        $router.push({
                          name: 'user-editor',
                          params: { id: data.author.id, data: data.author },
                        })
                      "
                    >
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-select
                  v-model="data.author.companiesIds"
                  :items="mapCompanies"
                  attach
                  chips
                  label="Empresas associadas"
                  solo
                  multiple
                  readonly
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-col cols="12" sm="12" md="5">
              <v-text-field
                v-model="associatedName"
                label="Nome do associado"
                placeholder="Ex: John Doe"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="associatedDocument"
                label="CPF/CNPJ"
                placeholder="Ex: 111.111.111-44"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="associatedEmail"
                label="Email"
                placeholder="Ex: john@company.com"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="9">
              <v-text-field
                v-model="specificationLocation"
                label="Localização"
                placeholder="Ex: Rua Pereira Santos 556"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="specificationDate"
                label="Data do laudo"
                placeholder="Ex: 01/01/2021"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="vehicleTag"
                label="Placa do veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="7">
              <v-text-field
                v-model="vehicleModel"
                label="Modelo do Veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-text-field
                v-model="vehicleState"
                label="UF do Veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="vehicleBrand"
                label="Marca do Veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="vehicleYear"
                label="Ano do Veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="vehicleMileage"
                label="Kilometragem"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                v-model="vehicleAccessories"
                :items="vehicleAccessories"
                label="Acessórios do veículo"
                readonly
                hide-details
                filled
                multiple
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!--<v-row>
            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <v-text-field
                v-model="tiresCondition"
                label="Estado dos pneus"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <v-text-field
                v-model="tiresBrand"
                label="Marca dos pneus"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <v-text-field
                v-model="tiresWidth"
                label="Largura pneus (mm)"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>   

            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <v-text-field
                v-model="tiresHeight"
                label="Altura pneus (mm)"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>   

            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <v-text-field
                v-model="tiresRadius"
                label="Raio pneus (mm)"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>   
          </v-row>

          <template>
            <h4 class="mb-1 mt-6">Avarias</h4>
            <div class="no-damages" v-if="!hasDamages">
              Veículo não possui avarias
            </div>

            <div class="vehicle-damages" v-else>
              <div
                class="damage-item mb-3"
                v-for="damage in vehicleDamages"
                :key="damage._id"
              >
                <div class="inspection-damage-placeholder">
                  <focus-point :value="{ x: damage.point[0], y: damage.point[1] }">
                    <template slot="pin">
                      <img src="@/assets/damage-marker.svg" width="70" height="70" />
                    </template>

                    <v-img
                      width="200"
                      height="200"
                      :src="damage.placeholder"
                      fit="contain"
                    >
                      <template slot="loading">
                        <v-progress-circular
                          :size="20"
                          :width="3"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </template>
                    </v-img>
                  </focus-point>
                </div>

                <div class="inspection-damage-topics mx-2">
                  <div class="inspection-damage-topic"><b>Tipo de avaria:</b> {{ damage.type }}</div>
                  <div class="inspection-damage-topic"><b>Local da avaria:</b> {{ damage.placement }}</div>
                  <div class="inspection-damage-topic"><b>Descrição da avaria:</b> {{ damage.description }}</div>
                </div>
              </div>
            </div>
          </template>

          <template>
            <h4 class="mb-1 mt-6">Carroceria</h4>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="3"
              >
                <v-text-field
                  v-model="bodyworkBrand"
                  label="Marca da carroceria"
                  readonly
                  hide-details
                  filled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="bodyworkModel"
                  label="Modelo da carroceria"
                  readonly
                  hide-details
                  filled
                ></v-text-field>
              </v-col>    
              <v-col
                cols="12"
                sm="12"
                md="3"
              >
                <v-text-field
                  v-model="bodyworkValue"
                  label="Preço da carroceria"
                  readonly
                  hide-details
                  filled
                ></v-text-field>
              </v-col>        
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="2"
              >
                <v-text-field
                  v-model="bodyworkTiresCondition"
                  label="Estado dos pneus"
                  readonly
                  hide-details
                  filled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                  v-model="bodyworkTiresBrand"
                  label="Marca dos pneus"
                  readonly
                  hide-details
                  filled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="2"
              >
                <v-text-field
                  v-model="bodyworkTiresWidth"
                  label="Largura pneus (mm)"
                  readonly
                  hide-details
                  filled
                ></v-text-field>
              </v-col>   

              <v-col
                cols="12"
                sm="12"
                md="2"
              >
                <v-text-field
                  v-model="bodyworkTiresHeight"
                  label="Altura pneus (mm)"
                  readonly
                  hide-details
                  filled
                ></v-text-field>
              </v-col>   

              <v-col
                cols="12"
                sm="12"
                md="2"
              >
                <v-text-field
                  v-model="bodyworkTiresRadius"
                  label="Raio pneus (mm)"
                  readonly
                  hide-details
                  filled
                ></v-text-field>
              </v-col>   
            </v-row>
          </template>-->

          <v-row v-for="cat in categoriesKeys" :key="cat">
            <v-col cols="12" sm="12" md="12">
              <h4>{{ cat }}</h4>
              <div class="inspection-images">
                <div
                  class="inspection-image"
                  v-for="(image, index) in categories[cat]"
                  :key="index"
                >
                  <div class="inspection-image-placeholder">
                    <div class="inspection-image-title">
                      {{ image.displayName }}
                    </div>
                    <img :src="image.url" width="200" height="200" />
                  </div>
                  <div class="inspection-image-buttons">
                    <v-btn
                      @click="openImage(image.url)"
                      color="primary"
                      class="mr-2"
                    >
                      <v-icon size="20">mdi-open-in-new</v-icon>
                    </v-btn>

                    <v-btn
                      @click="openImage(image.placeholder)"
                      color="orange"
                      dark
                    >
                      <v-icon size="20">mdi-file-image-outline</v-icon>
                      MODELO
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-5"></v-divider>
        <v-card-actions>
          <v-btn @click="$router.back()" text>
            <v-icon>mdi-chevron-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="green"
            :disabled="!allRequiredFieldsFilled || !dataIsModified"
            large
            class="btn mt-4 green primary--text"
            @click="submit()"
            :loading="$wait.waiting('creating') || $wait.waiting('updating')"
          >
            <v-icon class="mr-2">mdi-check</v-icon>
            {{ isEdit ? "Salvar" : "Cadastrar" }}
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapWaitingActions } from "vue-wait";
import checksum from "@/helpers/checksum";
import formatDate from "@/plugins/formatDate";
import _ from "lodash";
//import FocusPoint from "vue-focuspoint-component";

export default {
  name: "InspectionEditor",

  components: {
    //FocusPoint,
  },

  data() {
    return {
      isEdit: false,
      initialState: null,
      data: {
        createdAt: null,
        notes: null,
        vehicleType: null,
        status: null,
        images: [],
        quotation: null,
        specification: {
          date: null,
          location: null,
          associated: {
            name: null,
            document: null,
            email: null,
          },
          vehicle: {
            tag: null,
            mileage: null,
            tires: {
              condition: null,
              brand: null,
              height: null,
              width: null,
              radius: null,
            },
            bodywork: {
              enabled: false,
              model: null,
              value: null,
              tires: {
                condition: null,
                brand: null,
                height: null,
                width: null,
                radius: null,
              },
            },
            accessories: [],
            damages: [],
            hasNoDamages: false,
          },
        },
      },
    };
  },

  created() {
    const id = _.get(this.$route, "params.id", null);
    const data = _.get(this.$route, "params.data", null);

    if (id && id != "new" && data) {
      this.data = Object.assign({}, data);
      this.isEdit = true;
    } else if (id && id != "new" && !data) {
      this.data = null;
      return this.$router.replace({ name: "inspection" });
    }

    setTimeout(() => {
      this.initialState = Object.assign({}, this.data);
    }, 100);
  },

  watch: {
    dataIsModified(val) {
      if (!val) {
        return (window.onbeforeunload = null);
      }
      window.onbeforeunload = function () {
        return "exit";
      };
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.dataIsModified || this.confirmExit) {
      return next();
    }

    if (
      window.confirm(
        "Você tem alterações que não foram salvas. Deseja realmente sair?"
      )
    ) {
      return next();
    }
    try {
      await this.$router.replace(from);
    } catch (err) {
      return err;
    }
  },

  computed: {
    ...mapState("auth", ["companies"]),
    dataIsModified() {
      return checksum(this.initialState) != checksum(this.data);
    },

    allRequiredFieldsFilled() {
      const fields = [];
      return fields.every((field) => this.data[field]);
    },

    mapCompanies() {
      return this.companies.map((company) => ({
        text: company.name,
        value: company.id,
      }));
    },

    status() {
      return _.get(this.data, "status", null);
    },

    associatedName() {
      return _.get(this.data, "specification.associated.name", null);
    },

    associatedDocument() {
      return _.get(this.data, "specification.associated.document", null);
    },

    associatedEmail() {
      return _.get(this.data, "specification.associated.email", null);
    },

    specificationLocation() {
      return _.get(this.data, "specification.location", null);
    },

    specificationDate() {
      return formatDate(
        _.get(this.data, "data.specification.date", this.data.createdAt)
      );
    },

    vehicleTag() {
      return _.get(this.data, "specification.vehicle.tag", null);
    },

    vehicleState() {
      return _.get(this.data, "quotation.vehicle.state", null);
    },

    vehicleBrand() {
      return _.get(this.data, "quotation.vehicle.brand", null);
    },

    vehicleModel() {
      return _.get(this.data, "quotation.vehicle.model", null);
    },

    vehicleYear() {
      return _.get(this.data, "quotation.vehicle.year", null);
    },

    vehicleMileage() {
      return _.get(this.data, "specification.vehicle.mileage", null);
    },

    vehicleAccessories() {
      return _.get(this.data, "specification.vehicle.accessories", []);
    },

    tiresCondition() {
      const condition = _.get(
        this.data,
        "specification.vehicle.tires.condition",
        null
      );
      return {
        new: "Novos",
        good: "Bons",
        poor: "Ruins",
      }[condition];
    },

    tiresBrand() {
      return _.get(this.data, "specification.vehicle.tires.brand", null);
    },

    tiresWidth() {
      return _.get(this.data, "specification.vehicle.tires.width", null);
    },

    tiresHeight() {
      return _.get(this.data, "specification.vehicle.tires.height", null);
    },

    tiresRadius() {
      return _.get(this.data, "specification.vehicle.tires.radius", null);
    },

    bodyworkModel() {
      return _.get(this.data, "specification.vehicle.bodywork.model", null);
    },

    bodyworkBrand() {
      return _.get(this.data, "specification.vehicle.bodywork.brand", null);
    },

    bodyworkValue() {
      return _.get(this.data, "specification.vehicle.bodywork.value", null);
    },

    bodyworkTiresCondition() {
      const condition = _.get(
        this.data,
        "specification.vehicle.bodywork.tires.condition",
        null
      );
      return {
        new: "Novos",
        good: "Bons",
        poor: "Ruins",
      }[condition];
    },

    bodyworkTiresBrand() {
      return _.get(
        this.data,
        "specification.vehicle.bodywork.tires.brand",
        null
      );
    },

    bodyworkTiresWidth() {
      return _.get(
        this.data,
        "specification.vehicle.bodywork.tires.width",
        null
      );
    },

    bodyworkTiresHeight() {
      return _.get(
        this.data,
        "specification.vehicle.bodywork.tires.height",
        null
      );
    },

    bodyworkTiresRadius() {
      return _.get(
        this.data,
        "specification.vehicle.bodywork.tires.radius",
        null
      );
    },

    hasDamages() {
      return _.get(this.data, "specification.vehicle.damages.length", 0);
    },

    vehicleDamages() {
      return _.get(this.data, "specification.vehicle.damages", []);
    },

    categories() {
      return _.groupBy(this.data && this.data.images, "category");
    },

    categoriesKeys() {
      return _.keys(this.categories);
    },
  },

  methods: {
    ...mapActions("inspection", ["create", "update", "approve", "decline"]),
    ...mapWaitingActions("inspection", {
      create: "creating",
      update: "updating",
      approve: "approving",
      decline: "declining",
    }),

    submit() {
      if (this.isEdit) {
        return this.updateRequest();
      }

      return this.createRequest();
    },

    async createRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.create(data);
      if (!result) return;

      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Vistoria criada com sucesso",
      });
      this.reset(result);
      this.$router.replace({ name: "inspection" });
    },

    async updateRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.update({
        id: this.data.id,
        inspection: data,
      });
      if (!result) return;

      this.reset(result);
      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Dados atualizados com sucesso",
      });
    },

    reset(result) {
      this.initialState = Object.assign({}, result);
      this.data = Object.assign({}, result);
    },

    openImage(url) {
      return window.open(url, "_blank");
    },

    async approveRequest() {
      const dialogResponse = window.confirm(
        "Após confirmar, não será possível fazer alterações na vistoria. \n\nDeseja aprovar a vistoria?"
      );
      if (!dialogResponse) return;

      try {
        const data = await this.approve(this.data.id);
        if (data && data.status) {
          this.data.status = data.status;
          this.initialState = Object.assign({}, this.data);
        }
      } catch (err) {
        return err;
      }
    },

    async declineRequest() {
      const notes = prompt("Observações da vistoria");
      if (!notes) return;

      try {
        const data = await this.decline({
          id: this.data.id,
          notes: notes,
        });
        if (data && data.status) {
          this.data.status = data.status;
          this.initialState = Object.assign({}, this.data);
        }
      } catch (err) {
        return err;
      }
    },
  },
};
</script>

<style scoped>
.scroll-y {
  overflow-y: auto;
  height: calc(100vh - 56px);
}
.inspection-images {
  display: flex;
  margin-top: 8px;
}

.inspection-image {
  margin-right: 12px;
  display: flex;
  flex-direction: column;
}

.inspection-image-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  position: absolute;
  width: 100%;
  z-index: 1;
  font-weight: bold;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  background: rgba(255, 255, 255, 0.9);
}

.inspection-image-placeholder > img {
  object-fit: cover;
}

.inspection-image-placeholder {
  border-radius: 8px;
  border: 1px solid #cacaca;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
  flex: none;
}
.inspection-image-buttons {
  display: flex;
}
.damage-marker {
  position: absolute;
}
.vehicle-damages {
  display: flex;
  flex-wrap: wrap;
}

.inspection-damage-placeholder {
  border-radius: 8px;
  border: 1px solid #cacaca;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
  width: 224px;
  height: 224px;
  padding: 12px;
  flex: none;
}
.damage-item {
  display: flex;
  flex-basis: 50%;
}
.focus-point {
  display: block;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  height: 200px;
  width: 200px;
}
.focus-point * {
  pointer-events: none;
  user-select: none;
}
</style>
<style>
.focus-point-pin {
  position: absolute;
  transition: all 0.2s ease-in-out;
  width: 70px;
  height: 70px;
}
</style>