<template>
  <v-card flat class="page">
    <v-card-title>
      Dashboard
      <v-spacer></v-spacer>

      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datesFormatted"
              label="Filtrar por data"
              prepend-icon="mdi-calendar"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
              solo
              height="56"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="effectiveDateRange"
            no-title
            scrollable
            range
            locale="pt-BR"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.menu.save(dates);
                fetchDates();
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="2" class="px-0">
        <v-select
          v-model="periodUnit"
          solo
          :height="48"
          hide-details
          full-width
          :items="periodUnitOptions"
          label="Periodicidade"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          prepend-icon="mdi-car"
          v-model="vehicleType"
          solo
          :height="48"
          hide-details
          full-width
          :items="items"
          label="Tipo do veículo"
        ></v-select>
      </v-col>
    </v-card-title>

    <v-container>
      <v-row>
        <v-col cols="4" col>
          <v-card class="pa-2" style="min-height: 300px">
            <bar-chart :dashboard="dashboard" />
          </v-card>
        </v-col>

        <v-col cols="8">
          <v-card class="pa-2" style="min-height: 300px">
            <line-chart
              :quotationStatus="dashboard && dashboard.quotationStatus"
              :periodUnit="periodUnit"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-4">
            <div>
              <v-checkbox
                v-model="selectAllCompanies"
                label="Todas as regionais"
                v-if="isAdmin"
                color="green"
              />
              <v-combobox
                v-model="companiesFilter"
                :disabled="selectAllCompanies"
                :items="mapCompanies"
                attach
                chips
                label="Filtrar por Regional"
                :loading="$wait.waiting('loadingMe')"
                multiple
                item-text="text"
                item-value="id"
              ></v-combobox>
            </div>

            <v-combobox
              v-model="userFilter"
              v-if="users && users.length"
              item-text="name"
              item-value="id"
              :items="users"
              attach
              chips
              label="Filtrar por consultor"
              :loading="$wait.waiting('loadingMe')"
              multiple
            ></v-combobox>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapWaitingActions } from "vue-wait";
import { mapState, mapActions, mapGetters } from "vuex";
import vehicleTypes from "../../helpers/vehicleTypes";
import formatDate from "@/plugins/formatDate";
import LineChart from "../../components/LineChart.vue";
import BarChart from "../../components/BarChart.vue";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "Dashboard",

  data() {
    return {
      menu: false,
      dates: [],
      items: [{ value: "", text: "Todos" }, ...vehicleTypes],
      periodUnitOptions: [
        {
          value: "month",
          text: "Mensal",
        },
        {
          value: "day",
          text: "Diária",
        },
        {
          value: "week",
          text: "Semanal",
        },
      ],
      periodUnit: "month",
      vehicleType: "",
      companiesFilter: "",
      userFilter: null,
      selectAllCompanies: true,
      users: [],
      dashboard: null,
    };
  },

  components: { LineChart, BarChart },

  computed: {
    ...mapGetters("auth", ["isAdmin"]),
    ...mapState("auth", ["companies", "companiesIds"]),
    datesFormatted() {
      if (!this.dates || !this.dates[0] || !this.dates[1]) return "";
      return `${formatDate(this.dates[0], "DD/MM/YYYY")} à ${formatDate(
        this.dates[1],
        "DD/MM/YYYY"
      )}`;
    },

    mapCompanies() {
      return this.companies.map((company) => ({
        text: company.name,
        value: company.id,
      }));
    },

    effectiveDateRange: {
      set(val) {
        this.dates = val.sort();
      },
      get() {
        return this.dates;
      },
    },
  },

  created() {
    this.companiesFilter = null;
  },

  watch: {
    selectAllCompanies(val) {
      if (val) {
        this.companiesFilter = null;
      }
    },

    periodUnit() {
      this.fetch();
    },

    vehicleType() {
      this.fetch();
    },

    userFilter() {
      this.fetch();
    },

    async companiesFilter(val) {
      if (val && val.length) {
        val = val.map((c) => c.value);
      }
      const users = await this.filterUsers({
        companyId: val,
      });

      this.users = users;
      this.fetch();
    },
  },

  methods: {
    ...mapWaitingActions("auth", {
      getMe: "loadingMe",
      list: "loadingUsers",
    }),
    ...mapWaitingActions("dashboard", {
      getDashboard: "loading",
    }),
    ...mapActions("user", ["filterUsers"]),
    ...mapActions("dashboard", ["getDashboard"]),

    fetchDebounce: _.debounce(
      async function (params) {
        this.dashboard = await this.getDashboard({ ...params });
      },
      500,
      { leading: false, trailing: true }
    ),

    fetch() {
      const companiesIds =
        this.companiesFilter && this.companiesFilter.map((u) => u.value);
      const authorId = this.userFilter && this.userFilter.map((u) => u.id);
      this.fetchDebounce({
        companiesIds: companiesIds,
        authorId: authorId,
        vehicleType: this.vehicleType || undefined,
        "createdAt>": this.dates[0],
        "createdAt<": this.dates[1],
        periodUnit: this.periodUnit,
      });
    },

    fetchDates() {
      if (!this.dates[0] && !this.dates[1]) return;
      if (!this.dates[0]) {
        this.dates[0] = this.dates[1];
      }
      if (!this.dates[1]) {
        this.dates[1] = this.dates[0];
      }
      const diff = dayjs(this.dates[1]).diff(this.dates[0], "days");
      if (diff >= 28) {
        return (this.periodUnit = "month");
      } else if (diff >= 14) {
        this.periodUnit = "week";
      } else {
        this.periodUnit = "day";
      }

      this.fetch();
    },
  },
};
</script>
<style type="text/css" scoped>
.page {
  overflow-y: auto;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
}
</style>