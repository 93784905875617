<template>
  <v-row justify="center" class="scroll-y" v-if="data">
    <v-col cols="12" sm="10" md="10" lg="10">
      <v-card ref="form" class="pa-3">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="6">
            <h2 class="primary--text pl-3 pt-3">
              {{ isEdit ? "Detalhes da cotação" : "Criar cotação" }}
            </h2>
          </v-col>
          <v-col cols="12" sm="12" md="6" align="right">
            <v-btn color="blue" class="mr-2 white--text" @click="download()">
              Download
              <v-icon right dark> mdi-cloud-download </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text>
          <div v-if="data.createdAt">
            <strong>Cotação criada em:</strong>
            {{ data.createdAt | FormatDate("datetime") }}
          </div>

          <div v-if="data.author">
            <div><strong>Cotação criada por:</strong></div>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="data.author.name"
                  placeholder="Ex: John Doe"
                  solo
                  readonly
                  required
                >
                  <template v-slot:append>
                    <v-btn
                      class="ma-0"
                      text
                      @click="
                        $router.push({
                          name: 'user-editor',
                          params: { id: data.author.id, data: data.author },
                        })
                      "
                    >
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-select
                  v-model="data.author.companiesIds"
                  :items="mapCompanies"
                  attach
                  chips
                  label="Empresas associadas"
                  solo
                  multiple
                  readonly
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <v-text-field
            v-model="data.customer.name"
            label="Nome do associado"
            placeholder="Ex: John Doe"
            readonly
            hide-details
            class="mb-3"
            filled
          ></v-text-field>

          <v-row>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="vehicleTag"
                label="Placa do veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="7">
              <v-text-field
                v-model="vehicleModel"
                label="Modelo do Veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-text-field
                v-model="vehicleState"
                label="UF do Veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="vehicleBrand"
                label="Marca do Veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="vehicleYear"
                label="Ano do Veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="vehiclePrice"
                label="Preço do Veículo"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <!--<v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="PAR"
                label="PAR"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="daysOfBackupVehicle"
                label="Carro Reserva"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="implement"
                label="Total de Implemento ($)"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="emergencyAssistanceCoverage"
                label="Assistência 24h"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="participationQuota"
                label="Cota de participação"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="rearviewHeadlightFlashlightCoverage"
                label="Proteção à vidros, retrovisores, faróis e lanternas"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
          </v-row>-->

          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="finalPurchasePrice"
                label="Adesão"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="recurrencePrice"
                label="Mensalidade"
                readonly
                hide-details
                filled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-5"></v-divider>
        <v-card-actions>
          <v-btn @click="$router.back()" text>
            <v-icon>mdi-chevron-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <!--           <v-btn
            color="green"
            :disabled="!allRequiredFieldsFilled || !dataIsModified"
            large
            class="btn mt-4 secondary primary--text"
            @click="submit()"
            :loading="$wait.waiting('creating') || $wait.waiting('updating')"
          >
            {{ isEdit ? "Salvar" : "Cadastrar" }}
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import formatCents from "@/helpers/formatCents";
import { mapActions, mapState } from "vuex";
import { mapWaitingActions } from "vue-wait";
import checksum from "@/helpers/checksum";
import Environment from "@/environment";
import _ from "lodash";

export default {
  name: "QuotationEditor",

  data() {
    return {
      isEdit: false,
      initialState: null,
      data: {
        createdAt: null,
        customer: {
          name: null,
        },
        author: {
          name: null,
          email: null,
          cpf: null,
          id: null,
        },
        proposal: {
          daysOfBackupVehicle: null,
          discountPurchasePrice: null,
          emergencyAssistanceCoverage: null,
          finalPurchasePrice: null,
          par: null,
          participationQuota: null,
          purchasePriceWithoutDiscount: null,
          rearviewHeadlightFlashlightCoverage: null,
          recurrencePrice: null,
        },
        vehicle: {
          brand: null,
          fipeURL: null,
          model: null,
          price: null,
          state: null,
          tag: null,
          truckImplementsValues: [],
          type: null,
          year: null,
        },
      },
    };
  },

  created() {
    const id = _.get(this.$route, "params.id", null);
    const data = _.get(this.$route, "params.data", null);

    if (id && id != "new" && data) {
      this.data = Object.assign({}, data);
      this.isEdit = true;
    } else if (id && id != "new" && !data) {
      this.data = null;
      return this.$router.replace({ name: "quotation" });
    }

    setTimeout(() => {
      this.initialState = Object.assign({}, this.data);
    }, 100);
  },

  watch: {
    dataIsModified(val) {
      if (!val) {
        return (window.onbeforeunload = null);
      }
      window.onbeforeunload = function () {
        return "exit";
      };
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.dataIsModified || this.confirmExit) {
      return next();
    }

    if (
      window.confirm(
        "Você tem alterações que não foram salvas. Deseja realmente sair?"
      )
    ) {
      return next();
    }
    try {
      await this.$router.replace(from);
    } catch (err) {
      return err;
    }
  },

  computed: {
    ...mapState("auth", ["companies"]),
    dataIsModified() {
      return checksum(this.initialState) != checksum(this.data);
    },

    allRequiredFieldsFilled() {
      const fields = ["name", "cpf", "email"];
      return fields.every((field) => this.data[field]);
    },

    mapCompanies() {
      return this.companies.map((company) => ({
        text: company.name,
        value: company.id,
      }));
    },

    vehicleTag() {
      return _.get(this.data, "vehicle.tag", null);
    },

    vehicleState() {
      return _.get(this.data, "vehicle.state", null);
    },

    vehiclePrice() {
      return formatCents(_.get(this.data, "vehicle.price", 0));
    },

    vehicleBrand() {
      return _.get(this.data, "vehicle.brand", null);
    },

    vehicleModel() {
      return _.get(this.data, "vehicle.model", null);
    },

    vehicleYear() {
      return _.get(this.data, "vehicle.year", null);
    },

    PAR() {
      const value = _.get(this.data, "proposal.par", null);

      return value ? formatCents(value) : null;
    },

    implement() {
      const implementValues = _.get(
        this.data,
        "vehicle.truckImplementsValues",
        []
      );
      var value = 0;
      for (var i = 0; i <= implementValues.length; i++) {
        if (implementValues[i]) {
          value += implementValues[i];
        }
      }
      return value ? formatCents(value) : null;
    },

    participationQuota() {
      const value = _.get(this.data, "proposal.participationQuota", null);
      return (
        {
          common: "Passeio",
          overquota: "Diferenciado",
        }[value] || null
      );
    },

    daysOfBackupVehicle() {
      const value = _.get(this.data, "proposal.daysOfBackupVehicle", null);

      return value ? value + " dias" : null;
    },

    emergencyAssistanceCoverage() {
      const value = _.get(
        this.data,
        "proposal.emergencyAssistanceCoverage",
        null
      );

      return value ? value + " km" : null;
    },

    rearviewHeadlightFlashlightCoverage() {
      const value = _.get(
        this.data,
        "proposal.rearviewHeadlightFlashlightCoverage",
        null
      );

      return value ? "INCLUSO" : "NÃO INCLUSO";
    },

    recurrencePrice() {
      let value = _.get(this.data, "proposal.recurrencePrice", null);

      if (!value) {
        value = _.get(this.data, "proposal.recurrencePriceBronze", null);
      }

      return value ? formatCents(value) : null;
    },

    finalPurchasePrice() {
      const value = _.get(this.data, "proposal.finalPurchasePrice", null);

      return value ? formatCents(value) : null;
    },

    quotationDownloadURL() {
      return Environment.API_URL + "/quotation/" + this.data._id + "/download";
    },
  },

  methods: {
    ...mapActions("quotation", ["create", "update"]),
    ...mapWaitingActions("quotation", {
      create: "creating",
      update: "updating",
    }),

    submit() {
      if (this.isEdit) {
        return this.updateRequest();
      }

      return this.createRequest();
    },

    async createRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.create(data);
      if (!result) return;

      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Cotação criada com sucesso",
      });
      this.reset(result);
      this.$router.replace({ name: "quotation" });
    },

    async updateRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.update({
        id: this.data.id,
        quotation: data,
      });
      if (!result) return;

      this.reset(result);
      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Dados atualizados com sucesso",
      });
    },

    reset(result) {
      this.initialState = Object.assign({}, result);
      this.data = Object.assign({}, result);
    },

    download() {
      return window.open(this.quotationDownloadURL, "_blank");
    },
  },
};
</script>

<style scoped>
.scroll-y {
  overflow-y: auto;
  height: calc(100vh - 56px);
}
</style>
